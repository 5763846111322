<router-outlet></router-outlet>
<!--<div *ngIf="hasUpdate">
  <button (click)="reloadSite()">Reload</button>
</div>-->



<countdown-message></countdown-message>
<div >
  <!--Scroll to top-->
  <button [ngClass]="{'show-scroll': showScrollTop}" (click)="scrollToTop()" class="scroll-to-top">
    <span class="material-icons">
      north
    </span>
  </button>
  <!--Scroll to Bottom-->
  <button [ngClass]="{'show-scroll': showScrollBottom}" (click)="scrollToBottom()" class="scroll-to-bottom">
    <span class="material-icons">
      south
    </span>
  </button>
</div>


<div class="popup-overlay" *ngIf="hasUpdate" >
  <div class="popup-content">
    <!-- header -->
    <div>
      <h5>{{ 'New Version Detected!' | translate }}</h5>
    </div>

    <hr />

    <!-- Body -->
    <div style="text-align:center">
      <div>
        <p>{{ 'Please press to reload!' | translate }}</p>
      </div>

      <div style="display:flex;justify-content:center;">
        <button style="width:200px" mat-flat-button color="warn" (click)="reloadSite()">
          <!--<i class="fa fa-file-pdf-o"></i>-->
          {{'RELOAD' | translate}}
        </button>
      </div>

    </div>
  </div>
</div>
