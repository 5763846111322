/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/router";
import * as i8 from "./components-global/countdown-message/countdown-message.component.ngfactory";
import * as i9 from "./components-global/countdown-message/countdown-message.component";
import * as i10 from "./_services/countdowmessagepopup.service";
import * as i11 from "@angular/common";
import * as i12 from "./app.component";
import * as i13 from "./_services/authentication.service";
import * as i14 from "@angular/service-worker";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "popup-overlay"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "popup-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 9, "div", [["style", "text-align:center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["style", "display:flex;justify-content:center;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 3, "button", [["color", "warn"], ["mat-flat-button", ""], ["style", "width:200px"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reloadSite() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(14, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(15, 0, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_4 = "warn"; _ck(_v, 14, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("New Version Detected!")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("Please press to reload!")); _ck(_v, 10, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 14).disabled || null); var currVal_3 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 13, 0, currVal_2, currVal_3); var currVal_5 = i1.ɵunv(_v, 15, 0, i1.ɵnov(_v, 16).transform("RELOAD")); _ck(_v, 15, 0, currVal_5); }); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "countdown-message", [], null, null, null, i8.View_CountdownMessageComponent_0, i8.RenderType_CountdownMessageComponent)), i1.ɵdid(3, 114688, null, 0, i9.CountdownMessageComponent, [i10.CountdownMessagePopupService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "button", [["class", "scroll-to-top"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scrollToTop() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i11.ɵNgClassImpl, i11.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(7, 278528, null, 0, i11.NgClass, [i11.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(8, { "show-scroll": 0 }), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" north "])), (_l()(), i1.ɵeld(11, 0, null, null, 5, "button", [["class", "scroll-to-bottom"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scrollToBottom() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i11.ɵNgClassImpl, i11.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(13, 278528, null, 0, i11.NgClass, [i11.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(14, { "show-scroll": 0 }), (_l()(), i1.ɵeld(15, 0, null, null, 1, "span", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" south "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(18, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); _ck(_v, 3, 0); var currVal_0 = "scroll-to-top"; var currVal_1 = _ck(_v, 8, 0, _co.showScrollTop); _ck(_v, 7, 0, currVal_0, currVal_1); var currVal_2 = "scroll-to-bottom"; var currVal_3 = _ck(_v, 14, 0, _co.showScrollBottom); _ck(_v, 13, 0, currVal_2, currVal_3); var currVal_4 = _co.hasUpdate; _ck(_v, 18, 0, currVal_4); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onWindowScroll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i12.AppComponent, [i2.TranslateService, i13.AuthenticationService, i14.SwUpdate], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i12.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
