import { Component, OnInit, Renderer, ViewChild, ElementRef, AfterViewChecked, OnDestroy, Renderer2, ChangeDetectorRef } from '@angular/core';
import { ROUTES, NAVITEMS } from '../../sidebar/sidebar.component';
import { Router, NavigationEnd, ActivatedRouteSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { AuthenticationService } from '../../_services/authentication.service';
import { UserInfoService, } from '../../_services/userInfo.service';
import { ReceiptService, NotificationsService, ToasterType, ToasterPosition, /*SignalRService,*/ SystemValuesService, UsersService, GenericService, IdleService, ProtectorService } from '../../_services';
import { Observable, forkJoin, merge, concat, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { startWith, map, filter, switchMap, mapTo } from 'rxjs/operators';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { VersionModalComponent } from '../../pages/versionmodal/versionmodal.component';
import { ImpersonationControlService } from '../../_services/impersonationcontrolservice.service';



@Component({
  moduleId: module.id,
  selector: 'navbar-cmp',
  templateUrl: 'navbar.component.html'
})


export class NavbarComponent implements OnInit, OnDestroy {
  private listTitles: any[];
  location: Location;
  private nativeElement: Node;
  private toggleButton;
  private sidebarVisible: boolean;
  public isImpersonating: boolean = false;
  private runningUpdate = 0;
  currentPageName = '';

  public updateMsg = '';
  public version = '';

  public DBName = '';

  public menuDisplayName = '';
  public menuDisplayFontSize = '';

  //private signalRSubscription: Subscription;

  impersonationControl = new FormControl();
  public showHelpButton = false;

  filteredUsersImpersonations: Observable<any>;

  public isCollapsed = true;
  @ViewChild("navbar-cmp", { static: false }) button;

  public allowView_AdminTools = false;
  public allowView_CommunicationTemplates = false;

                          
  constructor(location: Location
    , private renderer: Renderer
    , private element: ElementRef
    , public router: Router
    , public authService: AuthenticationService
    , public usrInfo: UserInfoService
    , private receiptService: ReceiptService
    , private notificationService: NotificationsService
    //, private signalRService: SignalRService
    , private systemValuesService: SystemValuesService
    , public dialog: MatDialog,
    private protectService: ProtectorService,
    private usersService: UsersService,
    public genericService: GenericService,
    private cdr: ChangeDetectorRef,
    public idleService: IdleService,
    private impersonationControlService: ImpersonationControlService
  ) {
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;

    this.allowView_AdminTools = this.usrInfo.allowView('ADMINTOOLS');
    this.allowView_CommunicationTemplates = this.usrInfo.allowView('COMMUNICATIONTEMPLATES');


    this.renderer.listen('window', 'click', (e: Event) => {

      //if it is open then we close it, otherwise we do nothing
      if (!this.isCollapsed) {

        const navbar = document.getElementById('navbar');
        var isInNavBar = false;

        if ((e.target as Element).id == 'navbar') {
          //if found do nothing (means its an element inside navbar)
          isInNavBar = true;
        }
        else {
          var parent = (e.target as Element).parentElement;

          while (parent.parentElement != null) {
            if (parent.id == 'navbar') {
              //if found do nothing (means its an element inside navbar)
              isInNavBar = true;
              break;
            }

            parent = parent.parentElement;
          }
        }

        if (!isInNavBar) {
          this.isCollapsed = true;
          navbar.classList.add('navbar-transparent');
          navbar.classList.remove('bg-white');
        }

      }
    });


    //
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        // Function you want to call here
        this.updateSystemValues();
        this.checkIfImpersonationReset();
      }
    });

  }

  checkIfImpersonationReset() {
    //// Use setTimeout to allow ImpersonationControlComponent to update its state
    setTimeout(() => {

      if (this.impersonationControlService.isImpersonationActive()) {

        //check if there is saved impersonation
        var savedLastImpersonationUsername = (localStorage.getItem("savedLastImpersonationUsername") == undefined || localStorage.getItem("savedLastImpersonationUsername") == undefined == null) ? '' : JSON.parse(this.protectService.decrypt(localStorage.getItem("savedLastImpersonationUsername")));

        if (savedLastImpersonationUsername == '') {

          this.usrInfo.getSession('impersonated_username').subscribe(data => {
            //console.log('savedLastImpersonationUsername : ', data[0]);
            localStorage.setItem("savedLastImpersonationUsername", this.protectService.encryptionTypescript(JSON.stringify(data[0])));
          });

        }

      } else {

        //check if there is saved impersonation
        var savedLastImpersonationUsername = (localStorage.getItem("savedLastImpersonationUsername") == undefined || localStorage.getItem("savedLastImpersonationUsername") == undefined == null) ? '' : JSON.parse(this.protectService.decrypt(localStorage.getItem("savedLastImpersonationUsername")));

        if (savedLastImpersonationUsername != '') {

          this.impersonationSelectedNavbar(savedLastImpersonationUsername);

          //remove the impersonation back to the original before we came in contact
          localStorage.removeItem("savedLastImpersonationUsername");
        }


        //  console.log('Component not found.'); // Log if not found
      }
    }, 0); // A small delay allows for the component to update*/
  }


  impersonationSelectedNavbar(selectedOption: any) {



    //if object or string
    if (selectedOption.Username == undefined && selectedOption.Username == null) {

      selectedOption = this.usrInfo.getLoggedInUserImpersonations.filter(dt => dt.Username.toLowerCase() == selectedOption.toString().toLowerCase())[0];

      if (selectedOption == undefined || selectedOption == null) {

        //then it means that the user access the product without having impersonation access to it

        this.router.navigate(['/userproductsformslist'], { replaceUrl: true })

      }

    }

    //console.log('hello');

    //call the service and pass the object to trigger the event behavior from navbar
    this.impersonationSelected(selectedOption);

    this.impersonationControl.patchValue(selectedOption);


  }

  onDataChange(newData: any) {

    //this.impersonationSelected(newData);
    this.impersonationControl.patchValue(newData);
  }

  ngOnInit() {

    this.genericService.productImpersonationOptionData.subscribe(data => {
      this.onDataChange(data);
    });




    //get current page
    this.currentPageName = this.router.url.split('?')[0];

    //console.log(this.currentPageName);

    //get db name
    this.systemValuesService.GetDBName().subscribe(res => {

      this.DBName = res.responseObject;

    },
      error => {
        //  this.notificationService.show(ToasterType.error, error, ToasterPosition.topCenter, 4000);
      });

    //this.listTitles = ROUTES.filter(listTitle => listTitle);    

    //this.signalRSubscription=this.signalRService.responseReceived.subscribe((respData: any) => {

    //});
    this.listTitles = NAVITEMS; //.filter(listTitle => listTitle);

    var navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    this.router.events.subscribe((event) => {
      this.sidebarClose();
    });

    this.filteredUsersImpersonations = this.impersonationControl.valueChanges
      .pipe(startWith(''),
        map(value => typeof value === 'string' ? value : value.Username),
        switchMap(value => this._impersonationsfilter(value))
      );



    this.usrInfo.isLoggedInDataCompleteObs.subscribe(value => {

      if (value) {
        // this.usrInfo.startNotificationsService();
        var session_username = null;
        this.usrInfo.getSession('impersonated_username')
          .subscribe(response => {
            session_username = response;

            if (session_username == null) {
              session_username = this.usrInfo.getLoggedInUser[0]["Username"];
            }
            //session_username = "";
            var sessionUser = this.usrInfo.getLoggedInUserImpersonations.filter(dt => dt.Username.toLowerCase() == session_username.toString().toLowerCase());
            this.impersonationControl.patchValue(sessionUser[0]);
            this.impersonationSelected(sessionUser[0]);



            if (sessionUser[0]['VersionModal'] == undefined || sessionUser[0]['VersionModal'] == 0 || sessionUser[0]['VersionModal'] == null) {

              //update user modal (never show again if the user saw it)
              this.usersService.versionModalUpdate().subscribe(data => {

              });

              this.showVersionModal();


            }



          },
            error => {

            },
            () => {


            });
      }
    }
    );

    //get the route title
    this.getTitle();


  }

  ngOnDestroy() {
    //this.signalRService.disconnectSignalR();
    //.signalRSubscription.unsubscribe();
  }

  public runUpdateDailyReport() {

    this.runningUpdate = 1;

    this.receiptService.updatePaidReceiptsByAgent().subscribe(res => {
    },
      error => {
        this.notificationService.show(ToasterType.error, error, ToasterPosition.topCenter, 4000);
      },
      () => {
        this.notificationService.show(ToasterType.success, "Update successful!", ToasterPosition.topCenter, 4000);
        this.runningUpdate = 2;
      });

  }

  changeLanguage(lang: string) {
    this.genericService.translate.use(lang);

    localStorage.setItem("localLanguage", lang);
    //this.cdr.markForCheck();
    //this.cdr.detectChanges();
  }

  public updateSaltForAllUsers() {


    this.usersService.getUsers().subscribe(data => {

      var count = 0;


      //for each user
      data.forEach(user => {

        var bcrypt = require('bcryptjs');
        var generatedSalt = bcrypt.genSaltSync(10);
        user['Salt'] = generatedSalt;

        this.usersService.update(user['UserID'], user).subscribe(res => {


        })

        count++;

      });


    });


  }

  private _impersonationsfilter(value: string) {
    const filterValue = value.toLowerCase();
    return this.usrInfo.getLoggedInUserImpersonationsObs.pipe(
      //filter(data => !!data),
      map((data) => {
        if (data && data.length > 0) {

          return data.filter(option => option.Username.toLowerCase().includes(filterValue));
        }
      }));

  }

  updateSystemValues() {
    //display update description if its ready for a version
    this.systemValuesService.getByValue('UPDATE').subscribe(data => {

      if (data != undefined && data != null && data.length > 0) {

        if (data[0]['Status'] == 'A') {
          this.updateMsg = data[0]['Description'];
        }
        else {
          this.updateMsg = '';
        }

        this.version = data[0]['Version'];


      } else {
        this.updateMsg = '';
        this.version = '';
      }





    })

  }




  impersonationSelected(selectedOption: any) {

    if (this.usrInfo.getLoggedInUser == undefined) {
      return;
    }

    var currentPageName = this.router.url.split('?')[0].replace('/', '').split(';')[0];


    var excludePages = ['productacceptance', 'productgdpr', 'otppage', 'jcc-response-products', 'covernoteform', 'quotation_motor19', 'motorform', 'maxisafehomepolicyproposalform17', 'fireandrelatedform','medicalmalpracticeliabilityform22'];

    if (excludePages.includes(currentPageName)) {

      //this.notificationService.show(ToasterType.warning, 'Impersonation functionality is disable for this page!', ToasterPosition.topCenter, 4000);
      return;
    }

    //console.log('impersonationSelected : ', selectedOption);

    this.impersonationControl.patchValue(selectedOption);


    if (selectedOption.Username == this.usrInfo.getLoggedInUser[0].Username) {
      this.isImpersonating = false;
    }
    else {
      this.isImpersonating = true;
    }

    var previous_username = null;
    this.showHelpButton = this.usrInfo.allowView('HELP');
    this.usrInfo.getSession('impersonated_username').pipe(
      map(res => { return res; }),
      switchMap(res => { return this.setImpersonationSessionData(selectedOption).pipe(map(setRes => { return res; })) }
      ))
      .subscribe(response => {

        previous_username = response;


        if (previous_username != null && previous_username != selectedOption.Username) {

          this.genericService.impersonationChanged = true;

          //var url = this.router.url.substring(1, this.router.url.length);
          //var splitted = url.split(';');
          //url = splitted[0];
          //var params = 'null';
          //if (splitted.length > 1)
          //  params = splitted[1];

          ////close panel
          //this.closePanel();

          //setTimeout(() => this.router.navigate(['/navigateredirect', { comp: url, id: params }]), 500);

          var url = this.router.url.substring(1); // Remove the leading slash
          var splitted = url.split(';'); // Split the URL by ';'
          var baseUrl = splitted[0]; // Base URL
          var params = {}; // Object to hold parameters

          if (splitted.length > 1) {
            for (let i = 1; i < splitted.length; i++) {
              const paramPair = splitted[i].split('='); // Split by '='
              if (paramPair.length === 2) {
                const key = decodeURIComponent(paramPair[0]); // Decode key
                const value = decodeURIComponent(paramPair[1]); // Decode value
                params[key] = value; // Add to params object
              }
            }
          }

          // Close panel
          this.closePanel();

          // Use setTimeout to navigate
          setTimeout(() => {
            this.router.navigate(['/navigateredirect', { comp: baseUrl, ...params }]); // Spread params into the navigation
          }, 500);
        }

      },
        error => {

        },
        () => {


        });

  }

  private setImpersonationSessionData(selectedOption: any) {


    this.usrInfo.setImpersonationAgentCode = selectedOption;
    return this.usrInfo.setSession('impersonated_username', selectedOption.Username).pipe(
      switchMap(val => { return this.usrInfo.setSession('impersonated_userID', selectedOption.UserID) }),
      switchMap(val => { return this.usrInfo.setSession('impersonated_agentcode', selectedOption.AgentCode) }),
      switchMap(val => { return this.usrInfo.setSession('impersonated_subagentcode', selectedOption.SubAgentCode) })
    );
  }

  displayFU(user: any): string {
    return user && user.Username ? user.Username : "";
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());

    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(2);
    }

    var splitted = titlee.split(';');
    titlee = splitted[0];


    for (var item = 0; item < this.listTitles.length; item++) {

      var temp = this.listTitles[item];
      if (this.listTitles[item].children != undefined && this.listTitles[item].children != null) {
        //start recursive
        temp = this.recursiveMenu(this.listTitles[item].children, titlee)
      }

      if (temp.route === titlee) {
        this.menuDisplayName = temp.displayName;
        this.menuDisplayFontSize = temp.fontSize;

        //return this.genericService.translate.instant(temp.displayName);
        return this.genericService.translate.instant(temp.displayName);
      }



    }

    //if it is a page without being in the menu the we show a default or empty
    return '';
  }
  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }
  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName('html')[0];
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);

    html.classList.add('nav-open');
    if (window.innerWidth < 991) {
      mainPanel.style.position = 'fixed';
    }
    this.sidebarVisible = true;
  };
  sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    if (window.innerWidth < 991) {
      setTimeout(function () {
        mainPanel.style.position = '';
      }, 500);
    }
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
  };

  collapse() {

    this.isCollapsed = !this.isCollapsed;

    const navbar = document.getElementsByTagName('nav')[0];

    if (!this.isCollapsed) {
      navbar.classList.remove('navbar-transparent');
      navbar.classList.add('bg-white');
    } else {
      navbar.classList.add('navbar-transparent');
      navbar.classList.remove('bg-white');
    }

  }

  closePanel() {

    const navbar = document.getElementsByTagName('nav')[0];

    //if it is open then we close it, otherwise we do nothing
    if (!this.isCollapsed) {
      this.isCollapsed = true;
      navbar.classList.add('navbar-transparent');
      navbar.classList.remove('bg-white');
    }

  }

  logout() {
    if (this.authService.currentUserValue) {
      this.impersonationControl.setValue('');
      //close all timers and events from idle service
      //this.idleService.StopAllTimersAndEvents();
      this.authService.logout();
      this.usrInfo.clearLoggedInUserData();
    }

    //    this.signalRService.disconnectSignalR();

  }

  recursiveMenu(listTitles, titlee) {


    for (var item = 0; item < listTitles.length; item++) {
      if (listTitles[item].children != undefined && listTitles[item].children != null) {
        //start recursive
        this.recursiveMenu(listTitles[item].children, titlee)
      }

      if (listTitles[item].route === titlee) {
        return listTitles[item];
      }
    }

    //if it is a page without being in the menu the we show a default or empty
    return '';

  }


  //this one
  help() {

    const dialogData = new ConfirmDialogModel("Help!", "");
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      panelClass: 'custom-dialog-container'
    });
  }


  showVersionModal() {

    //display popup
    const dialogConfig = new MatDialogConfig();

    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    dialogConfig.height = "90%";
    dialogConfig.width = "99%";
    dialogConfig.panelClass = "cdk-overlay-container";

    // https://material.angular.io/components/dialog/overview
    const modalDialog = this.dialog.open(VersionModalComponent, dialogConfig);

  }




}
