import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpClient, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ToastrModule } from "ngx-toastr";
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// fontawesome
import { AppComponent } from './app.component';
import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { FixedPluginModule } from './shared/fixedplugin/fixedplugin.module';


import { AppRoutes } from './app.routing';
import { JwtInterceptor, ErrorInterceptor, appInitializer } from './_helpers';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { CustomMaterialModule } from './shared/confirm-dialog/custom-material.module';

import { CountdownMessageComponent } from './components-global/countdown-message/countdown-message.component';
//import { NgOtpInputModule } from 'ng-otp-input';

//import { VersionModalModule } from '../../src/app/pages/versionmodal/versionmodal.module';

//loader
import { LoaderInterceptor } from './_helpers/loader.interceptor';
import { LoaderService } from './_services/loader.service';

//error service & compon
import { ErrorService } from './_services/error.service';
import { ErrorComponent } from './error/error.component';

//services
import {
  RecurrentTaskService, ReceiptAllocationsService, ReceiptService, ManualReceiptRegisterService, NotificationsService, GenericService, BranchService,
  ClientBalancesService, DocumentCodesService, PostingReceiptRequestService, PolicyCoverNotesService, PolicyPermissionLineService, PolicyDriverService, PolicyItemService, PolicyPermissionService,
  ItemService, DriverService, GenericCodesService, ClientService, EmailLogService, EventLogService, SMSLogService, SubUserService, SyClientsService, DocumentsService, CommonService,
  AuthenticationService, UsersService, ModulesAccessService, ReportsService, OXSService, UserInfoService, ProtectorService, /*SignalRService,*/ SecurityService, JCCDepositService, DashboardService,
  LoginLogService, JCCStatementService, BankStatementImportingService, PancyprianService, AttachmentsService, SystemValuesService, RefundsService, SevenDaysNoticeService
  , CarAbroadCoverService, VersionModalService, DuobondService, ProductsService, OTPLogService, ClaimsService, ProductsAcceptanceService, CountdownMessagePopupService, IdleService, CommunicationService, AccountingService
} from './_services';
import { SetLoggedInUserResolverService } from './_services/setloggedinuser_resolver.service';
import { DatePipe, DecimalPipe } from "@angular/common";
import { MAT_DATE_LOCALE, MatDatepickerModule, MatNativeDateModule, MatSortModule } from "@angular/material";
import { PolicyService } from "./_services/policy.service";

import { CKEditorModule } from 'ckeditor4-angular';
import { InputWithLimitComponent } from "./shared/inputwithlimit/inputwithlimit.component";
import { InputWithLimitModule } from "./shared/inputwithlimit/inputwithlimit.module";

import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
import { environment } from '../environments/environment';


// -------------------------------------------------------------------------

//const cookieConfig: NgcCookieConsentConfig = {
//  cookie: {
//    domain: 'localhost' // it is recommended to set your domain, for cookies to work properly
//  },
//  position: 'bottom-right',
//  theme:'classic',
//  palette: {
//    popup: {
//      background: '#000'
//    },
//    button: {
//      background: '#f1d600'
//    }
//  },
//  type: 'opt-in',

//content: {
//message: 'This website uses cookies to ensure you get the best experience on our website.',
//dismiss: 'Got it!',
//deny: 'Refuse cookies',
//link: 'Learn more',
//href: 'https://www.ydrogios.com.cy/el/gdpr',
//policy: 'Cookie Policy'
//}
//};

export class NoCacheHeadersInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authReq = req.clone({
      setHeaders: {
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        Pragma: 'no-cache'
      }
    });
    return next.handle(authReq);
  }
}


@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
    AdminLayoutComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    CountdownMessageComponent
    ],
  imports: [

    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SidebarModule,
    NavbarModule,
    ToastrModule.forRoot(),
    FooterModule,
    FixedPluginModule,
    CustomMaterialModule,
    MatDatepickerModule,
    MatNativeDateModule,
    CKEditorModule,
    MatSortModule,
    InputWithLimitModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
    ,
    //NgOtpInputModule,
   
    RouterModule.forRoot(AppRoutes, {
      useHash: true, scrollPositionRestoration: 'enabled'
    })
  ],
  providers: [
    BranchService,
    GenericService,
    NotificationsService,
    ManualReceiptRegisterService,
    ReceiptService,
    RecurrentTaskService,
    ReceiptAllocationsService,
    ClientBalancesService,
    DocumentCodesService,
    PostingReceiptRequestService,
    PolicyCoverNotesService, PolicyPermissionLineService, PolicyDriverService, PolicyItemService, PolicyPermissionService, PolicyService,
    ItemService,
    DriverService,
    GenericCodesService,
    ClientService,
    EmailLogService, EventLogService, SMSLogService, LoginLogService,
    SubUserService,
    SyClientsService,
    DocumentsService,
    CommonService,
    AuthenticationService,
    UsersService,
    ModulesAccessService,
    ReportsService,
    LoaderService,
    OXSService,
    DashboardService,
    ProtectorService,
    UserInfoService,
    SecurityService,
    //SignalRService,
    JCCDepositService,
    JCCStatementService,
    BankStatementImportingService,
    PancyprianService,
    SystemValuesService,
    DatePipe,
    DecimalPipe,
    SetLoggedInUserResolverService,
    AttachmentsService,
    RefundsService,
    SevenDaysNoticeService,
    CarAbroadCoverService,
    VersionModalService,
    DuobondService,
    ProductsService,
    ProductsAcceptanceService,
    OTPLogService,
    ClaimsService,
    CountdownMessagePopupService,
    IdleService,
    CommunicationService,
    AccountingService,
    SwUpdate,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    //{ provide: MAT_DATE_LOCALE, useValue: 'el' },
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthenticationService, SetLoggedInUserResolverService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NoCacheHeadersInterceptor, multi: true },
    //{ provide: ErrorHandler, useClass: ErrorService },
    JwtHelperService
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}

export function httpTranslateLoader(http: HttpClient) {

  return new TranslateHttpLoader(http);

}

